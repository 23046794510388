import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Web_User_Interface/720p_Series/Alarm/Actions/PrimaryBox';
import ActionTable from 'components/Web_User_Interface/720p_Series/Alarm/Actions/actionTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Actions",
  "path": "/Web_User_Interface/720p_Series/Alarm/Actions/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Adjust the cameras behaviour in case of a motion alert trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your SMTP Server settings before activating the email notification and that you have a SD card in your cameras SDHC card slot (max. 32 GB) to use the video recording and send-to-FTP function.",
  "image": "./WebUI_720p_SearchThumb_Alarm_Actions.jpg",
  "social": "/images/Search/WebUI_720p_SearchThumb_Alarm_Actions.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Alarm Actions' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Adjust the cameras behaviour in case of a motion alert trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your SMTP Server settings before activating the email notification and that you have a SD card in your cameras SDHC card slot (max. 32 GB) to use the video recording and send-to-FTP function.' image='/images/Search/WebUI_720p_SearchThumb_Alarm_Actions.png' twitter='/images/Search/WebUI_720p_SearchThumb_Alarm_Actions.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Alarm/Aktionen/' locationFR='/fr/Web_User_Interface/720p_Series/Alarm/Actions/' crumbLabel="Alarm Actions" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "alarm-actions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-actions",
        "aria-label": "alarm actions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Actions`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Adjust the cameras behaviour in case of a motion alert trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your SMTP Server settings before activating the email notification and that you have a SD card in your cameras SDHC card slot (max. 32 GB) to use the video recording and send-to-FTP function.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c43241d533cd6425168f5dd81904423/6bbf7/WebUI-Alarm_Actions.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAAC+UlEQVQ4y3WT327iRhjFea22b1HtRdv7PEMutzdRWylX+acsSLDKkuxV4hKXgmvYZBessPESN0HgP3hm7PHMeOwxOCp5gMr2AiFVf7ejo3O+b75T+r3R+OHHn7759rvvX73q9/v39/fD4dAwDNM0rU1M00QIXVxIW1tbtVqtUq6UZFk+Pj7+5dffyuWK67qWZU4mE9OykiWzZ8znc855rVb7cJVROjs7e3/2XpKkTqdDgsBDXu5icR6laTrbJEkSIUSr1TJySicnJ3t7e+/q9Xa7jTH2Pd8y7akzDQLCOU+SJE3T+ZIkScIwvLy8/JJTqtfrlUpFupAURcGZGju2A1wXQujYDvbxc/FsNovjeC0+PT2tVquSJLXb7SAIsI9t24EAuNNMDwFIktn/imtv3755c1wul8/PzymlCMLJeGJb9mQ8gQA4jsMYW5m/FF+cZ9ze6r1eD2Ns25ZhGKPRCEKUJEnEeRRFq4UJIRhja7GHPIQg5zyOY8bY1JmORg9BEMzncyFE8Vsip9gWxliW5a9iWZZ/fv16e3tbkiQhhOd5hBDGwlXUdMnj46MQghCydgYAjh5GvudFuTNwAYIIQRDxaOW5cuacU0rXYgQRDcjT01OappRS1wUIeZ6HCCHL1OtT45xvxM7G8PHin8VisaCUAgBgDvK8/zqHYbgRu9ls/iFfNv9s9nq97J8xDgJCCMEYh2H4/ELSNI3jmBCydlaUv66urlVVVRSFUcoYgxAhhDzkBUFQlOE5SZKsne//fpiMzfF4PBwOKaXF86oGLxBCbBxJv99vtVuKogwGA8aY7/suABBA6ELXBS+Sv7ywjx8/dTodVVU1TQvDMI7jIB+a5iMwxsIcIcSqkmvxYDBQVfVDt/v58y0hpFhpUcY8/8bAhXOj0fgq1jSt2+1eX1/f3NxkxxxFWTEDXGweQkgICZcUJWk2m7quZ2Jd/9LrfdI0zTAMRVGOjo4ODg4Ol+zs7Ozu7h4eHh7k7O/vV6vVer1+d3en6/q/wrYYWoj7nOQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c43241d533cd6425168f5dd81904423/e4706/WebUI-Alarm_Actions.avif 230w", "/en/static/4c43241d533cd6425168f5dd81904423/d1af7/WebUI-Alarm_Actions.avif 460w", "/en/static/4c43241d533cd6425168f5dd81904423/04ac8/WebUI-Alarm_Actions.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c43241d533cd6425168f5dd81904423/a0b58/WebUI-Alarm_Actions.webp 230w", "/en/static/4c43241d533cd6425168f5dd81904423/bc10c/WebUI-Alarm_Actions.webp 460w", "/en/static/4c43241d533cd6425168f5dd81904423/d8378/WebUI-Alarm_Actions.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c43241d533cd6425168f5dd81904423/81c8e/WebUI-Alarm_Actions.png 230w", "/en/static/4c43241d533cd6425168f5dd81904423/08a84/WebUI-Alarm_Actions.png 460w", "/en/static/4c43241d533cd6425168f5dd81904423/6bbf7/WebUI-Alarm_Actions.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c43241d533cd6425168f5dd81904423/6bbf7/WebUI-Alarm_Actions.png",
              "alt": "INSTAR 720p Web User Interface - Alarm Actions (General)",
              "title": "INSTAR 720p Web User Interface - Alarm Actions (General)",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig1.`}</strong>{` INSTAR 720p Web User Interface - Alarm Actions (General)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/6bbf7/WebUI-Alarm_Actions_I.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC8ElEQVQ4y4WU3VLbRhiGfVtpLyM5aHrONXCYnrWZSQ7gBNlmcIYacgSqcTWWLbuQ2BoclEAwseSf9f5oV9JK2pWUmAvoWC2Rgc70OX/m+/Z9d7f0R6Px0/Ofn/zw49Nnz/r9/vX1tWVZo9FoMpk4jjNZw7ZthJCqqhsbG7VarVqplprNpqIov/72slKtzudzAIBt24yxNE3lI5Ik4ZzXarW/TleUDg8P3x6+VVW12+1S6rouQRAuIJzP577vJ0myLsdxzBhrtVpXOaX9/f3t7e3f63Vdb2OMCcEALCCCAIBFDuc8TdMkR0rJOW80Gh9zSvV6vVqtqsdqu90hhIRh6Lo0CILvM5P7SCkL+eDgYG9vT1VVXdddQhBCtmNDCLOcx2YYhicnJ//KtTdvymWlUqkcHR1TSl3i5gtDQghj7IEshPA8r5CPj1ZcXFiDwcAlZDab3Yxuxl/GtmNPp9MHWwshCCGFjBFGCHLOoygKAh9jDABACAGwiup/1m42m7+8eLG5uamqqpQCIgTytgEA09k04DzLsvQOKaXv+4W8WMCb0Q3BOAxDHgTj8fhz3iGEEGPMGJNSijviOL43GUHkUXZ7e5umqe/7CwAcZ8IY+5qzPjbLMiEExriQgyBwibv8tlwul57vzeZzx3E8z5NCRFEUx7FYI4oixlgha5r2Z/NE07R+v++6BAAA4aqn+BFCCM45QqiQ2+3O6emZYRidTsd1XZxDKRX5TX4A5/xeVdefR/bY+TIef/p0SSlFCJHVPcOMef8ktE4YhpTSQh4MBi291W63h8MhpS5CiDFGKQ3DcD2t/w7s3bv33W7XMAzTNFc55cVIKdYbWq+Kc17Iw+HQMIxer/fhwwVjlBACwAIAEMVxlmUP2kqSJAzD4lWZptnr9c7Ozs7Pz7+fyvM8SlfLz6YzeHcQmv8VURRpmmZZ1kq2rI/9/nvTNK+urnRdVxRl5w5FUV69frW1tVUul5WcnZ2d3d3der1+eXlpWdbf3JMY94PQ2KsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc8f6748dd12b04da865dbb2991dd1f1/e4706/WebUI-Alarm_Actions_I.avif 230w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/d1af7/WebUI-Alarm_Actions_I.avif 460w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/04ac8/WebUI-Alarm_Actions_I.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bc8f6748dd12b04da865dbb2991dd1f1/a0b58/WebUI-Alarm_Actions_I.webp 230w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/bc10c/WebUI-Alarm_Actions_I.webp 460w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/d8378/WebUI-Alarm_Actions_I.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc8f6748dd12b04da865dbb2991dd1f1/81c8e/WebUI-Alarm_Actions_I.png 230w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/08a84/WebUI-Alarm_Actions_I.png 460w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/6bbf7/WebUI-Alarm_Actions_I.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/6bbf7/WebUI-Alarm_Actions_I.png",
              "alt": "INSTAR 720p Web User Interface - Alarm Actions (General)",
              "title": "INSTAR 720p Web User Interface - Alarm Actions (General)",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig2.`}</strong>{` INSTAR 720p Web User Interface - Alarm Actions (Cameras with integrated PIR)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/6bbf7/WebUI-Alarm_Actions_I.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC8ElEQVQ4y4WU3VLbRhiGfVtpLyM5aHrONXCYnrWZSQ7gBNlmcIYacgSqcTWWLbuQ2BoclEAwseSf9f5oV9JK2pWUmAvoWC2Rgc70OX/m+/Z9d7f0R6Px0/Ofn/zw49Nnz/r9/vX1tWVZo9FoMpk4jjNZw7ZthJCqqhsbG7VarVqplprNpqIov/72slKtzudzAIBt24yxNE3lI5Ik4ZzXarW/TleUDg8P3x6+VVW12+1S6rouQRAuIJzP577vJ0myLsdxzBhrtVpXOaX9/f3t7e3f63Vdb2OMCcEALCCCAIBFDuc8TdMkR0rJOW80Gh9zSvV6vVqtqsdqu90hhIRh6Lo0CILvM5P7SCkL+eDgYG9vT1VVXdddQhBCtmNDCLOcx2YYhicnJ//KtTdvymWlUqkcHR1TSl3i5gtDQghj7IEshPA8r5CPj1ZcXFiDwcAlZDab3Yxuxl/GtmNPp9MHWwshCCGFjBFGCHLOoygKAh9jDABACAGwiup/1m42m7+8eLG5uamqqpQCIgTytgEA09k04DzLsvQOKaXv+4W8WMCb0Q3BOAxDHgTj8fhz3iGEEGPMGJNSijviOL43GUHkUXZ7e5umqe/7CwAcZ8IY+5qzPjbLMiEExriQgyBwibv8tlwul57vzeZzx3E8z5NCRFEUx7FYI4oixlgha5r2Z/NE07R+v++6BAAA4aqn+BFCCM45QqiQ2+3O6emZYRidTsd1XZxDKRX5TX4A5/xeVdefR/bY+TIef/p0SSlFCJHVPcOMef8ktE4YhpTSQh4MBi291W63h8MhpS5CiDFGKQ3DcD2t/w7s3bv33W7XMAzTNFc55cVIKdYbWq+Kc17Iw+HQMIxer/fhwwVjlBACwAIAEMVxlmUP2kqSJAzD4lWZptnr9c7Ozs7Pz7+fyvM8SlfLz6YzeHcQmv8VURRpmmZZ1kq2rI/9/nvTNK+urnRdVxRl5w5FUV69frW1tVUul5WcnZ2d3d3der1+eXlpWdbf3JMY94PQ2KsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc8f6748dd12b04da865dbb2991dd1f1/e4706/WebUI-Alarm_Actions_I.avif 230w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/d1af7/WebUI-Alarm_Actions_I.avif 460w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/04ac8/WebUI-Alarm_Actions_I.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bc8f6748dd12b04da865dbb2991dd1f1/a0b58/WebUI-Alarm_Actions_I.webp 230w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/bc10c/WebUI-Alarm_Actions_I.webp 460w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/d8378/WebUI-Alarm_Actions_I.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc8f6748dd12b04da865dbb2991dd1f1/81c8e/WebUI-Alarm_Actions_I.png 230w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/08a84/WebUI-Alarm_Actions_I.png 460w", "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/6bbf7/WebUI-Alarm_Actions_I.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bc8f6748dd12b04da865dbb2991dd1f1/6bbf7/WebUI-Alarm_Actions_I.png",
              "alt": "INSTAR 720p Web User Interface - Alarm Actions (General)",
              "title": "INSTAR 720p Web User Interface - Alarm Actions (General)",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig3.`}</strong>{` INSTAR 720p Web User Interface - Alarm Actions (Cameras without Alarm I/O)`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ActionTable mdxType="ActionTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "what-do-you-want-the-camera-to-do-in-case-of-an-alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-do-you-want-the-camera-to-do-in-case-of-an-alarm",
        "aria-label": "what do you want the camera to do in case of an alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What do you want the Camera to do in Case of an Alarm?`}</h3>
    <p>{`Adjust the cameras behaviour in case of a motion alert trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/SMTP_Server/"
      }}>{`SMTP Server Settings`}</a>{` before activating the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Email/"
      }}>{`Email Notification`}</a>{`.`}</p>
    {/* and that you have a SD card in your cameras SDHC card slot (max. 32 GB) to use the [Video Recording](/Web_User_Interface/720p_Series/Recording/) and [SEND-to-FTP Function](/Web_User_Interface/720p_Series/Alarm/FTP/) - a 4 GB SD card is included in the delivery. */}
    <h3 {...{
      "id": "software-based-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software-based-motion-detection",
        "aria-label": "software based motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software-based Motion Detection`}</h3>
    <p>{`The software-based motion detection used by your camera for the activated `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Areas/"
      }}>{`Alarm Areas`}</a>{` does not need any additional hardware to work and can be very precise in controlled indoor environments. The disadvantage is that the detection does not discriminate causes for changes inside the cameras field of view - a cloud moving by in front of the sun, raindrops or vegetation moving in the wind. Everything can cause an alarm.`}</p>
    <h3 {...{
      "id": "use-an-external-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-an-external-sensor",
        "aria-label": "use an external sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use an external Sensor`}</h3>
    <p>{`You can use a motion detector like the `}<a parentName="p" {...{
        "href": "/en/Products/IN-Motion/"
      }}>{`IN-MOTION 500`}</a>{` to improve the detection rate of your camera (Only cameras with an Alarm-In contact) if your camera does not yet come with a PIR motion sensor equipped like the IN-6014 HD. Simply connect the sensor to the alarm input and activate the external input. The camera will trigger an alarm when the pins 3&4 are bridged and the relay state is set to closed on alarm (n.o. “Normally Open”). If it is set to open on alarm, the alarm will be triggered once the circuit is broken by the relay inside the detector (n.c. “Normally Closed”) or if the wire is disconnected. The state you have to choose depends on your sensor - the wrong choice will give a continuous alert once the detector is connected.`}</p>
    <PrimaryBox mdxType="PrimaryBox" />
    <h3 {...{
      "id": "use-the-internal-sensor-in-6014-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-the-internal-sensor-in-6014-hd",
        "aria-label": "use the internal sensor in 6014 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use the internal Sensor (`}<strong parentName="h3">{`IN-6014 HD`}</strong>{`)`}</h3>
    <p>{`Activating the internal or external PIR sensor allows you to limit the motion detection to bodies whose temperature differs from the background. In most cases, this will be a person or animal in front of the cameras or the warm motor of a car. Changes in light conditions, fog in front of the camera at night or movements in the wind will not be detected by the PIR sensor.`}</p>
    <p>{`By coupling the PIR sensor with the detection areas, you will only get an alarm event if both detection methods were triggered at the same time. This will greatly reduce the number of false alerts you might have. But be aware that it also might eliminate a few positive alerts.`}</p>
    <h3 {...{
      "id": "switch-external-devices-in-case-of-an-alarm-or-manually",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#switch-external-devices-in-case-of-an-alarm-or-manually",
        "aria-label": "switch external devices in case of an alarm or manually permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Switch external devices in case of an alarm (or manually)`}</h3>
    <p>{`The external output connects to a relay inside the camera that can be switched manually or automatically in case of an alarm event. Please check Activate External Output to let the camera open or close a circuit with an external device connected to the pins 1&2. The external output can be used with the cameras motion detection to activate signalling devices like sirens or LED lights. It can also be used to control connected devices manually, e.g. via our smartphone app. The relay can be connected to circuits with up to 60 V direct current / 125 V alternating current and max. 1 A.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      